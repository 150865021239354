import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SwipeableDrawer } from '@mui/material';
import GhostButton from '../../../buttons/GhostButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddSummary from '../../drawers/AddSummary';
import { SELECTIVE_TABS } from '../../../../data/projectReportConstants';

const AddSummarySection = ({ report }) => {
  const navigate = useNavigate();
  const [isOpenAddSummary, setIsOpenAddSummary] = useState(false);

  const constructUrl = (id) => {
    return `/project-reports/monthly-report/${id}`;
  };

  const handleButtonClick = (id) => {
    const url = constructUrl(id);
    navigate(url);
  };

  return (
    <>
      <GhostButton
        onClick={() => {
          if (report.reportType === SELECTIVE_TABS.MONTHLY) {
            handleButtonClick(report.id);
          } else {
            setIsOpenAddSummary(true);
          }
        }}
        icon={
          <AddOutlinedIcon
            sx={{ marginRight: '-8px', fontSize: '18px !important' }}
            htmlColor='#0666EB'
          />
        }
      >
        Add
      </GhostButton>
      <SwipeableDrawer
        anchor={'right'}
        open={isOpenAddSummary}
        onClose={() => setIsOpenAddSummary(false)}
        onOpen={() => setIsOpenAddSummary(true)}
      >
        <div style={{ width: 560 }}>
          <AddSummary
            report={report}
            onClose={() => setIsOpenAddSummary(false)}
          />
        </div>
      </SwipeableDrawer>
    </>
  );
};

const validReportTypes = [SELECTIVE_TABS.MONTHLY, SELECTIVE_TABS.WEEKLY];

AddSummarySection.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reportType: PropTypes.oneOf(validReportTypes).isRequired,
    summary: PropTypes.string,
  }).isRequired,
};

export default AddSummarySection;
