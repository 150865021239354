import { format, addDays, startOfMonth, endOfMonth } from 'date-fns';

//get the abbreviation based on attendance status
export const getAbbreviation = (status) => {
  const statusMap = {
    Present: 'P',
    'Medical/Casual Leave': 'M/C',
    'Annual Leave': 'A',
    Weekend: 'W',
    'No Pay': 'N',
    Holiday: 'H',
  };
  return statusMap[status] || status;
};

//get the color associated with attendance
export const getRectangleColor = (abbreviation) => {
  const colorMap = {
    P: '#47B881',
    W: '#FFAD0D',
    N: '#E04545',
    H: '#7B61FF',
    A: '#EA38B8',
    'M/C': '#4594FF',
  };
  return colorMap[abbreviation] || abbreviation;
};

//generate week days from current week
export const getWeekDays = (currentDate, currentWeekStart) => {
  const startOfTheWeek = currentWeekStart;
  const endOfTheMonthDate = endOfMonth(currentDate);
  const startOfTheMonthDate = startOfMonth(currentDate);

  return Array.from({ length: 7 }).map((_, index) => {
    const day = addDays(startOfTheWeek, index);
    return {
      date: format(day, 'MMM d'),
      day: format(day, 'EEE'),
      isDisabled: day < startOfTheMonthDate || day > endOfTheMonthDate,
    };
  });
};
