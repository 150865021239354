import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Stack } from '@mui/material';
import ShortSecondaryBtn from '../buttons/ShortSecondaryBtn';
import ShortPrimaryBtn from '../buttons/ShortPrimaryBtn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  background: '#FFFFFF',
  borderRadius: '8px',
  width: '22%',
  padding: '1%',
};

const ApprovalModal = ({
  open,
  handleClose,
  titleComponent,
  descriptionComponent,
  isLoading,
  primaryBtnTxt,
  primaryLoadingBtnTxt,
  secondaryBtnTxt,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        {titleComponent}
        {descriptionComponent}
        <Stack
          sx={{ marginTop: '12px' }}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <ShortPrimaryBtn
            disabled={isLoading}
            text={isLoading ? primaryLoadingBtnTxt : primaryBtnTxt}
            onClick={onPrimaryBtnClick}
          />
          <ShortSecondaryBtn
            text={secondaryBtnTxt}
            onClick={onSecondaryBtnClick}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

ApprovalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  titleComponent: PropTypes.node.isRequired,
  descriptionComponent: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  primaryBtnTxt: PropTypes.string.isRequired,
  primaryLoadingBtnTxt: PropTypes.string.isRequired,
  secondaryBtnTxt: PropTypes.string.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
};

export default ApprovalModal;
