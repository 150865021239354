import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import ReportCardLayout from '../report-card/ReportCardLayout';
import DrawerLayout from './DrawerLayout';
import ReportDrawerSummary from '../report-card/ReportDrawerSummary';
import useApproveSummary from '../hooks/useApproveSummary';
import { Stack, Typography } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import ApprovalModal from '../../alerts/ApprovalModal';
import { useProjectReport } from '../../../contexts/project-reports-context/ProjectReportsContext';
import { CLOSING_DRAWER_TIMEOUT } from '../../../data/projectReportConstants';

const ApproveSummary = ({ report, onClose }) => {
  const [openModal, setOpenModal] = useState(false);
  const { actions } = useProjectReport();
  const { setModifiedReport } = actions;

  const { isLoading, handleApproveSummary } = useApproveSummary(report.id);

  const onApprovalClick = () => {
    setOpenModal(true);
  };

  const approveSummary = async () => {
    const approvedReport = await handleApproveSummary();
    if (approvedReport) {
      setOpenModal(false);
      onClose();
      setTimeout(() => {
        setModifiedReport(approvedReport);
      }, CLOSING_DRAWER_TIMEOUT);
    }
  };

  return (
    <>
      <DrawerLayout title={'Project report options'} onClose={onClose}>
        <Box sx={{ margin: '16px' }}>
          {report && (
            <ReportCardLayout report={report} showEditTime={true}>
              <ReportDrawerSummary
                report={report}
                onApprovalClick={onApprovalClick}
                isLoading={isLoading}
              />
            </ReportCardLayout>
          )}
        </Box>
      </DrawerLayout>
      {/* Modal */}
      <ApprovalModal
        open={openModal}
        titleComponent={
          <Typography
            id='modal-modal-title'
            fontSize={20}
            variant='h7'
            fontWeight={400}
          >
            Confirm Weekly Project Report Approval?
          </Typography>
        }
        descriptionComponent={
          <Stack sx={{ padding: '2% 0' }}>
            <Typography color={styleProps.colors.textLightGrey} variant='h7'>
              You have successfully written the weekly project summary. After
              confirmation, the project report will be posted to the associated
              Slack channel.
            </Typography>
          </Stack>
        }
        isLoading={isLoading}
        primaryBtnTxt={'Approve'}
        primaryLoadingBtnTxt={'Approving'}
        secondaryBtnTxt={'Cancel'}
        handleClose={() => setOpenModal(false)}
        onPrimaryBtnClick={approveSummary}
        onSecondaryBtnClick={() => setOpenModal(false)}
      />
    </>
  );
};

ApproveSummary.propTypes = {
  report: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ApproveSummary;
