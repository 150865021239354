/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  FormControlLabel,
  styled,
  TextField,
  Checkbox,
} from '@mui/material';
import { Grid } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import ExtraShortPrimaryBtn from '../buttons/ExtraShortPrimaryBtn';
import { getEmployeeByStatus } from '../../services/EmployeeService';
import { EmployeeStatus } from '../../enums/Employee';

const { fonts } = styleProps;

const TextInput = styled(TextField)({
  width: '100%',
  margin: '25px',
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: fonts.primary,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const EmployeePaystubSettingsSearch = ({
  selectedEmployees,
  setSelectedEmployees,
}) => {
  const [initialAllEmployees, setInitialAllEmployees] = useState([]);
  const [newSelectedEmployees, setNewSelectedEmployees] =
    useState(selectedEmployees);
  const [allEmployees, setAllEmployees] = useState([]);
  const [matchingEmployees, setMatchingEmployees] = useState([]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    removeSelectedEmployeesFromAllEmployees();
  }, [selectedEmployees, initialAllEmployees]);

  const getAllEmployees = async () => {
    const searchQuery = '';
    try {
      getEmployeeByStatus(EmployeeStatus.ENABLED).then((response) => {
        const employees = response.employees;

        if (Array.isArray(employees)) {
          const filteredEmployees = employees.filter((item) => {
            const fullName =
              `${item.employeeFirstName} ${item.employeeLastName}`.toLowerCase();
            const employeeId = item.employeeEmployeeId.toString().toLowerCase(); // Convert employee ID to string for comparison
            return (
              fullName.includes(searchQuery.toLowerCase()) ||
              employeeId.includes(searchQuery.toLowerCase())
            );
          });

          setAllEmployees(filteredEmployees);
          setInitialAllEmployees(filteredEmployees);
        }
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleChange = (e, item) => {
    let selectedEmployeesArray = newSelectedEmployees;
    if (e.target.checked && !selectedEmployees.includes(item)) {
      selectedEmployeesArray.push(item);
      setNewSelectedEmployees(selectedEmployeesArray);
    }
  };

  const addSelectedEmployees = async () => {
    const empList = [...newSelectedEmployees];
    setSelectedEmployees(empList);
  };

  function filterItemsByName(name) {
    if (name.trim() == '') {
      setMatchingEmployees([]);
    } else {
      const newMatchingEmployees = allEmployees.filter((item) => {
        const fullName =
          `${item.employeeFirstName} ${item.employeeLastName}`.toLowerCase();
        const employeeId = item.employeeEmployeeId.toString().toLowerCase();
        return (
          fullName.includes(name.toLowerCase()) ||
          employeeId.includes(name.toLowerCase())
        );
      });
      setMatchingEmployees(newMatchingEmployees);
    }
  }

  async function removeSelectedEmployeesFromAllEmployees() {
    const newAllEmployees = initialAllEmployees.filter(
      (item) => !selectedEmployees.includes(item),
    );
    setAllEmployees(newAllEmployees);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
      <div style={{ flex: 5 }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            <TextInput
              label='Search by ID or Name'
              name='search'
              onChange={(e) => {
                filterItemsByName(e.target.value);
              }}
              style={{ padding: 0, margin: 0 }}
            />
            {matchingEmployees.length > 0 && (
              <FormGroup
                style={{
                  backgroundColor: '#F5F7F8',
                  borderRadius: 4,
                  padding: 5,
                }}
              >
                {matchingEmployees.map((item) => (
                  // eslint-disable-next-line react/jsx-key
                  <FormControlLabel
                    control={
                      <Checkbox onChange={(e) => handleChange(e, item)} />
                    }
                    label={`${item.employeeFirstName} ${item.employeeLastName}`}
                    defaultChecked={
                      selectedEmployees.includes(item) == true ? true : false
                    }
                  />
                ))}
                <div style={{ alignSelf: 'flex-end' }}>
                  <ExtraShortPrimaryBtn
                    onClick={addSelectedEmployees}
                    text={'Add'}
                    disabled={false}
                  ></ExtraShortPrimaryBtn>
                </div>
              </FormGroup>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EmployeePaystubSettingsSearch;
