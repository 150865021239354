import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useProjectReport } from '../../../contexts/project-reports-context/ProjectReportsContext';
import DrawerLayout from './DrawerLayout';
import { DM_STATUS } from '../../../data/projectReportConstants';

const Filters = ({ onClose, filterHandler }) => {
  const { projectReports } = useProjectReport();
  const { dmStatus } = projectReports;

  return (
    <DrawerLayout title={'Filters'} onClose={onClose}>
      <Stack sx={{ padding: '16px' }}>
        <Typography sx={{ padding: '8px 0' }} fontWeight={500} variant='h7'>
          Approved by Delivery Manager
        </Typography>
        <Box sx={{ padding: '16px 0' }}>
          <FormGroup>
            <RadioGroup
              value={dmStatus}
              onChange={(e) => filterHandler({ _dmStatus: e.target.value })}
            >
              <FormControlLabel
                value={DM_STATUS.ALL}
                control={<Radio />}
                label='All'
              />
              <FormControlLabel
                value={DM_STATUS.APPROVED}
                control={<Radio />}
                label='Approved'
              />
              <FormControlLabel
                value={DM_STATUS.NOT_APPROVED}
                control={<Radio />}
                label='Not approved'
              />
            </RadioGroup>
          </FormGroup>
        </Box>
        {/* TODO: Will be used later */}
        {/* <Typography sx={{ padding: '8px 0' }} fontWeight={500} variant='h7'>Invoice generated by CBC</Typography>
        <Box sx={{ padding: '16px 0' }}>
          <FormGroup>
            <RadioGroup
              value={cbcStatus}
              onChange={(e) =>
                filterHandler({
                  _dmStatus: dmStatus,
                  _cbcStatus: e.target.value,
                })
              }
            >
              <FormControlLabel
                value={CBC_STATUS.ALL}
                control={<Radio />}
                label='All'
              />
              <FormControlLabel
                value={CBC_STATUS.GENERATED}
                control={<Radio />}
                label='Generated'
              />
              <FormControlLabel
                value={CBC_STATUS.NOT_GENERATED}
                control={<Radio />}
                label='Not generated'
              />
            </RadioGroup>
          </FormGroup>
        </Box> */}
      </Stack>
    </DrawerLayout>
  );
};

Filters.propTypes = {
  onClose: PropTypes.func.isRequired,
  filterHandler: PropTypes.func.isRequired,
};

export default Filters;
