import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';
import { Typography, Box, SwipeableDrawer } from '@mui/material';
import ShortPrimaryBtn from '../../buttons/ShortPrimaryBtn';
import { styleProps } from '../../../styles/styleProps';
import { format, parseISO } from 'date-fns';
import GhostButton from '../../buttons/GhostButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomDivider } from '.';
import AddSummary from '../drawers/AddSummary';

const ReportDrawerSummary = ({ report, onApprovalClick, isLoading }) => {
  const [isOpenAddSummary, setIsOpenAddSummary] = useState(false);
  return (
    <>
      <Stack
        sx={{ padding: '2%', paddingBottom: '0' }}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          fontSize={20}
          variant='h7'
          fontWeight={400}
          sx={{ paddingBottom: '0px' }}
        >
          Summary
        </Typography>
        {!report.approvedByDm && (
          <GhostButton
            onClick={() => setIsOpenAddSummary(true)}
            icon={
              <EditOutlinedIcon
                sx={{ marginRight: '-6px', fontSize: '18px !important' }}
                htmlColor='#0666EB'
              />
            }
          >
            Edit
          </GhostButton>
        )}
        <SwipeableDrawer
          anchor={'right'}
          open={isOpenAddSummary}
          onClose={() => setIsOpenAddSummary(false)}
          onOpen={() => setIsOpenAddSummary(true)}
        >
          <div style={{ width: 560 }}>
            <AddSummary
              report={report}
              onClose={() => setIsOpenAddSummary(false)}
            />
          </div>
        </SwipeableDrawer>
      </Stack>
      <Stack sx={{ padding: '2%' }} justifyContent={'space-between'}>
        <Typography color={styleProps.colors.textLightGrey} variant='h7'>
          {report.summary}
        </Typography>
      </Stack>
      <CustomDivider />
      {report.approvedByDm ? (
        <>
          <Stack sx={{ padding: '8px' }} flexDirection={'row'}>
            <CheckCircleIcon sx={{ fontSize: '20px' }} htmlColor='#000000' />
            <Box width={'2px'} />
            <Typography color={styleProps.colors.black} variant='h7'>
              {report.approvedBy} approved on{' '}
              {format(parseISO(report.approvedAt), 'MMMM dd, yyyy')}.
            </Typography>
          </Stack>
        </>
      ) : (
        <Box sx={{ padding: '2%' }} textAlign={'center'}>
          <ShortPrimaryBtn
            disabled={isLoading}
            text={isLoading ? 'Approving' : 'Approve'}
            onClick={onApprovalClick}
          />
        </Box>
      )}
    </>
  );
};

ReportDrawerSummary.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
    summary: PropTypes.string,
    approvedByDm: PropTypes.bool,
    approvedBy: PropTypes.string,
    approvedAt: PropTypes.string,
  }).isRequired,
  onApprovalClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ReportDrawerSummary;
