import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import ShortPrimaryBtn from '../../buttons/ShortPrimaryBtn';
import ShortSecondaryBtn from '../../buttons/ShortSecondaryBtn';
import TimeInputField from '../../input-field/TimeInputField';
import DrawerLayout from './DrawerLayout';
import useEditTime from '../hooks/useEditTime';
import { useProjectReport } from '../../../contexts/project-reports-context/ProjectReportsContext';
import { CLOSING_DRAWER_TIMEOUT } from '../../../data/projectReportConstants';

const EditEmployeeTime = ({ reportId, employees, onClose }) => {
  const [employeeData, setEmployeeData] = useState(
    employees.map((emp) => {
      return { ...emp, changed: false };
    }),
  );
  const { actions } = useProjectReport();
  const { setModifiedReport } = actions;

  const { isLoading, handleEditDuration } = useEditTime(reportId);

  const updatedRecords = employeeData
    .filter((emp) => emp.changed)
    .map((emp) => ({
      employeeDisplayId: emp.id,
      timeInMs: emp.timeInMs,
    }));

  const editDuration = (id, newDuration) => {
    setEmployeeData(
      employeeData.map((obj) =>
        obj.id === id ? { ...obj, timeInMs: newDuration, changed: true } : obj,
      ),
    );
  };

  const removeEmployee = (id, currentDuration) => {
    setEmployeeData(
      employeeData.map((obj) =>
        obj.id === id
          ? { ...obj, timeInMs: currentDuration, changed: false }
          : obj,
      ),
    );
  };

  const saveTimeDuration = async () => {
    const updatedReport = await handleEditDuration(updatedRecords);
    if (updatedReport) {
      onClose();
      // NOTE: Once the update is successful, at first, the drawer will be closed and
      // then the page will be refreshed gradually
      setTimeout(() => {
        setModifiedReport(updatedReport);
      }, CLOSING_DRAWER_TIMEOUT);
    }
  };

  return (
    <DrawerLayout title={'Edit time duration'} onClose={onClose}>
      <Stack
        sx={{ padding: '16px' }}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        {employees.map((employee) => {
          return (
            <Stack
              key={employee.id + reportId}
              sx={{
                margin: '8px 0',
              }}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Typography
                sx={{ padding: '16px 0' }}
                color={styleProps.colors.textLightGrey}
                variant='h7'
              >
                {employee.name}
              </Typography>
              <TimeInputField
                timeInMs={employee.timeInMs}
                onUpdate={(timeInMs) => {
                  editDuration(employee.id, timeInMs);
                }}
                onReset={() => removeEmployee(employee.id)}
              />
            </Stack>
          );
        })}
        <Box
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px 0px',
            width: '100%',
          }}
        >
          <Stack
            sx={{ margin: '0 16px' }}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <ShortSecondaryBtn text={'Cancel'} onClick={onClose} />
            <ShortPrimaryBtn
              disabled={!(!isLoading && updatedRecords.length)}
              text={isLoading ? 'Saving' : 'Save'}
              onClick={saveTimeDuration}
            />
          </Stack>
        </Box>
      </Stack>
    </DrawerLayout>
  );
};

EditEmployeeTime.propTypes = {
  reportId: PropTypes.string.isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      timeInMs: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditEmployeeTime;
