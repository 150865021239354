import FolderIcon from '@mui/icons-material/Folder';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HandshakeIcon from '@mui/icons-material/Handshake';

const timeLimit = 2;

const superAdminMenuItems = [
  {
    pageId: 'dashboard',
    hasSubMenu: false,
  },
  {
    pageId: 'payroll',
    hasSubMenu: false,
  },
  {
    pageId: 'employees',
    hasSubMenu: false,
  },
  {
    group: 'Project',
    icon: FolderIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'projects',
        hasSubMenu: false,
      },
      {
        pageId: 'projectReports',
        hasSubMenu: false,
      },
    ],
  },
  {
    group: 'Attendance',
    icon: CalendarTodayIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'hybridSchedule',
        hasSubMenu: false,
      },
      {
        pageId: 'attendanceSettings',
        hasSubMenu: false,
      },
      {
        pageId: 'attendance',
        hasSubMenu: false,
      },
    ],
  },
  {
    group: 'Onboarding',
    icon: HandshakeIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'offerLetterTemplates',
        hasSubMenu: false,
      },
      {
        pageId: 'offerLetters',
        hasSubMenu: false,
      },
    ],
  },
  {
    pageId: 'admins',
    hasSubMenu: false,
  },
  {
    pageId: 'profile',
    hasSubMenu: false,
  },
];

const adminMenuItems = [
  {
    pageId: 'dashboard',
    hasSubMenu: false,
  },
  {
    pageId: 'payroll',
    hasSubMenu: false,
  },
  {
    pageId: 'employees',
    hasSubMenu: false,
  },
  {
    group: 'Project',
    icon: FolderIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'projects',
        hasSubMenu: false,
      },
      {
        pageId: 'projectReports',
        hasSubMenu: false,
      },
    ],
  },
  {
    group: 'Attendance',
    icon: CalendarTodayIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'attendanceSettings',
        hasSubMenu: false,
      },
    ],
  },
  {
    group: 'Onboarding',
    icon: HandshakeIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'offerLetterTemplates',
        hasSubMenu: false,
      },
      {
        pageId: 'offerLetters',
        hasSubMenu: false,
      },
    ],
  },
  {
    pageId: 'admins',
    hasSubMenu: false,
  },
  {
    pageId: 'profile',
    hasSubMenu: false,
  },
];

const hrManagerMenuItems = [
  {
    pageId: 'dashboard',
    hasSubMenu: false,
  },
  {
    pageId: 'payroll',
    hasSubMenu: false,
  },
  {
    pageId: 'employees',
    hasSubMenu: false,
  },
  {
    group: 'Project',
    icon: FolderIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'projects',
        hasSubMenu: false,
      },
      {
        pageId: 'projectReports',
        hasSubMenu: false,
      },
    ],
  },
  {
    group: 'Attendance',
    icon: CalendarTodayIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'attendanceSettings',
        hasSubMenu: false,
      },
    ],
  },
  {
    group: 'Onboarding',
    icon: HandshakeIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'offerLetterTemplates',
        hasSubMenu: false,
      },
      {
        pageId: 'offerLetters',
        hasSubMenu: false,
      },
    ],
  },
  {
    pageId: 'profile',
    hasSubMenu: false,
  },
];

const deliveryManagerMenuItems = [
  {
    group: 'Project',
    icon: FolderIcon,
    hasSubMenu: true,
    subMenus: [
      {
        pageId: 'projects',
        hasSubMenu: false,
      },
      {
        pageId: 'projectReports',
        hasSubMenu: false,
      },
    ],
  },
  {
    pageId: 'profile',
    hasSubMenu: false,
  },
];

const MONTHS = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun',
  'July',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
];

const isLeapYear = () => {
  let leapYear = new Date(new Date().getFullYear(), 1, 29);
  return leapYear.getDate() == 29;
};

const DAYS = {
  1: 31,
  2: isLeapYear() ? 29 : 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

export {
  timeLimit,
  superAdminMenuItems,
  adminMenuItems,
  deliveryManagerMenuItems,
  hrManagerMenuItems,
  MONTHS,
  DAYS,
};
