import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CardSkeletonContainer from '../skeletons/CardSkeletonContainer';
import NoReportsFound from './NoReportsFound';
import ReportCardLayout from './report-card/ReportCardLayout';
import ReportSummary from './report-card/ReportSummary';
import MonthlyReport from './MonthlyReport';

const ReportsContainer = ({ isLoading, reports, isMonthlyReport = false }) => {
  if (isLoading) {
    return (
      <CardSkeletonContainer
        outerBoxSx={{
          width: '100%',
          border: '2px solid #DEEBFC',
          borderRadius: 4,
          height: '300px',
        }}
        innerBoxSx={{
          flexDirection: 'column',
          display: 'flex',
          padding: '2%',
          justifyContent: 'space-around',
        }}
      />
    );
  }
  if (!reports.length) {
    return <NoReportsFound />;
  } else {
    return (
      <>
        <>
          {isMonthlyReport ? (
            <MonthlyReport reports={reports} />
          ) : (
            <Grid
              gap={4}
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
            >
              {reports.map((report) => {
                return (
                  <ReportCardLayout
                    key={report.id}
                    report={report}
                    showEditTime={true}
                  >
                    <ReportSummary report={report} showEditSummary={true} />
                  </ReportCardLayout>
                );
              })}
            </Grid>
          )}
        </>
      </>
    );
  }
};

ReportsContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMonthlyReport: PropTypes.bool.isRequired,
};

export default ReportsContainer;
