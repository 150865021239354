import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, styled } from '@mui/system';
import { Typography, Divider, SwipeableDrawer } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useState } from 'react';
import EditEmployeeTime from '../drawers/EditEmployeeTime';
import GhostButton from '../../buttons/GhostButton';
import EmployeeTimeRecord from './EmployeeTimeRecord';
import {
  formatDuration,
  formatPeriod,
  makeTwoDigits,
} from '../../../utils/TemporalService';

const ReportCard = ({ report, showEditTime }) => {
  const [isOpenEditTimeDrawer, setIsOpenEditTimeDrawer] = useState(false);
  const { hrs, mins } = formatDuration(report.accumulatedTime);

  return (
    <>
      <Box sx={{ padding: '2%' }}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant='h7' fontSize={28} fontWeight={500}>
            {report.projectName}
          </Typography>
          <Box>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <CalendarMonthOutlinedIcon
                htmlColor={styleProps.colors.textLightGrey}
                sx={{ paddingRight: '4px' }}
              />
              <Typography color={styleProps.colors.textLightGrey} variant='h7'>
                {formatPeriod(report.period)}
              </Typography>
            </Stack>
            <Box height={'4px'} />
            <Stack flexDirection={'row'} alignItems={'center'}>
              <AccessTimeOutlinedIcon
                htmlColor={styleProps.colors.textLightGrey}
                sx={{ paddingRight: '4px' }}
              />
              <Typography color={styleProps.colors.textLightGrey} variant='h7'>
                <Box
                  alignContent={'center'}
                  display={'inline-flex'}
                  flexWrap={'wrap'}
                >
                  <Typography
                    color={styleProps.colors.textLightGrey}
                    variant='h7'
                  >
                    {makeTwoDigits(hrs)} hrs :
                  </Typography>
                  <Typography
                    color={styleProps.colors.textLightGrey}
                    variant='h7'
                    width={65}
                    sx={{ marginLeft: 'auto', textAlign: 'end' }}
                  >
                    {mins} mins
                  </Typography>
                </Box>
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <CustomDivider />
      <Stack
        sx={{ padding: '2%' }}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography fontSize={20} variant='h7' fontWeight={400}>
          Employees time duration
        </Typography>
        {showEditTime ? (
          <>
            {!report.approvedByDm && (
              <GhostButton
                onClick={() => setIsOpenEditTimeDrawer(true)}
                icon={
                  <EditOutlinedIcon
                    sx={{ marginRight: '-6px', fontSize: '18px !important' }}
                    htmlColor='#0666EB'
                  />
                }
              >
                Edit
              </GhostButton>
            )}
            <SwipeableDrawer
              anchor={'right'}
              open={isOpenEditTimeDrawer}
              onClose={() => setIsOpenEditTimeDrawer(false)}
              onOpen={() => setIsOpenEditTimeDrawer(true)}
            >
              <div style={{ width: 560 }}>
                <EditEmployeeTime
                  reportId={report.id}
                  employees={report.employees}
                  onClose={() => setIsOpenEditTimeDrawer(false)}
                />
              </div>
            </SwipeableDrawer>
          </>
        ) : (
          <></>
        )}
      </Stack>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography
          sx={{ padding: '0 2%' }}
          fontSize={18}
          variant='h7'
          fontWeight={400}
        >
          Employees
        </Typography>
        <Typography
          sx={{ padding: '0 2%' }}
          fontSize={18}
          variant='h7'
          fontWeight={400}
        >
          Time duration
        </Typography>
      </Stack>
      <Box sx={{ height: '21vh', overflowY: 'auto' }}>
        <Stack sx={{ padding: '2%' }} flexWrap={'wrap'} gap={2}>
          {report.employees
            .sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
            .map((employee, idx) => {
              return (
                <EmployeeTimeRecord
                  key={(idx + report.id + employee.id).replace(/-/g, '')}
                  employee={employee}
                />
              );
            })}
        </Stack>
      </Box>
    </>
  );
};

ReportCard.propTypes = {
  report: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    accumulatedTime: PropTypes.string.isRequired,
    employees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        timeInMs: PropTypes.number.isRequired,
      }),
    ).isRequired,
    summary: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    approvedByDm: PropTypes.bool.isRequired,
  }).isRequired,
  showEditTime: PropTypes.bool.isRequired,
  showEditSummary: PropTypes.bool.isRequired,
};

export const CustomDivider = styled(Divider)({
  borderColor: '#DEEBFC',
  borderWidth: '1px',
});

export default ReportCard;
