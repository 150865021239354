/* eslint-disable react/prop-types */
import React from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
const { fonts, colors } = styleProps;

const TableComponnet = ({
  columns,
  data,
  selectedEmployees,
  setSelectedEmployees,
}) => {
  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.employeeDisplayId);
      setSelectedEmployees(new Set(newSelecteds));
      return;
    }
    setSelectedEmployees(new Set());
  };

  const handleCheckboxClick = (employeeDisplayId) => {
    const isChecked = selectedEmployees.has(employeeDisplayId);
    let newSelected = new Set(selectedEmployees);

    if (isChecked) {
      newSelected.delete(employeeDisplayId);
    } else {
      newSelected.add(employeeDisplayId);
    }
    setSelectedEmployees(newSelected);
  };

  return (
    <Table stickyHeader sx={{ marginBottom: '80px' }}>
      <TableHead>
        <TableRow sx={{ border: 'none' }}>
          <TableCell align='left' sx={[tableHeaderStyles, { width: 10 }]}>
            <Checkbox
              onChange={handleSelectAllClick}
              checked={selectedEmployees.size === data.length}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 },
                '&.Mui-checked': { color: '#059669' },
              }}
            />
          </TableCell>
          {columns.map((headerName, index) => {
            return (
              <TableCell key={index} align='left' sx={tableHeaderStyles}>
                {headerName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody sx={{ border: 'none', backgroundColor: 'white' }}>
        {data.map((data, index) => {
          return (
            <>
              <TableRow key={index} sx={{ backgroundColor: '#F5F7F8' }}>
                <TableCell align='left' sx={[tableCellStyles, { width: 10 }]}>
                  <Checkbox
                    checked={selectedEmployees.has(data.employeeDisplayId)}
                    onChange={() => handleCheckboxClick(data.employeeDisplayId)}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 28 },
                      '&.Mui-checked': { color: '#059669' },
                    }}
                  />
                </TableCell>

                <TableCell align='left' sx={tableCellStyles}>
                  {data.employeeEmployeeId}
                </TableCell>
                <TableCell align='left' sx={tableCellStyles}>
                  {(data.employeeFirstName ?? '') +
                    ' ' +
                    (data.employeeLastName ?? '')}
                </TableCell>
                <TableCell align='left' sx={tableCellStyles}>
                  {data.employeeWorkEmail}
                </TableCell>
                <TableCell align='left' sx={tableCellStyles}>
                  {data.employeeDesignation}
                </TableCell>
                <TableCell align='left' sx={tableCellStyles}>
                  {data.Currency.currencyCode} {nf.format(data.employeeSalary)}
                </TableCell>
                <TableCell align='left' sx={tableCellStyles}>
                  {data.employeeMobile}
                </TableCell>
              </TableRow>

              {/* This is the divider */}
              <TableRow
                sx={{
                  backgroundColor: 'white',
                  height: '5px',
                }}
              ></TableRow>
            </>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableComponnet;

const tableCellStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
};

const tableHeaderStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: colors.textLightGrey,
};
