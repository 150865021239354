import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import ChevronDropdown from '../drop-down/ChevronDropdown';
import employeeData from '../../data/employeeData';
import {
  getAbbreviation,
  getRectangleColor,
} from '../../utils/attendanceUtils';
import { styleProps } from '../../styles/styleProps';

const { fonts } = styleProps;

const AttendanceTable = ({ weekDays }) => {
  const [employees, setEmployees] = useState(employeeData);

  const dropdownOptions = [
    'P - Present',
    'M/C - Medical/Casual Leave',
    'A - Annual Leave',
    'N - No Pay',
  ];

  const handleUpdateAbbreviation = (
    newAbbreviation,
    employeeIndex,
    dayIndex,
  ) => {
    const updatedEmployees = [...employees];
    const updatedStatus = newAbbreviation.split(' ')[0];
    updatedEmployees[employeeIndex].attendance[dayIndex].status = updatedStatus;
    setEmployees(updatedEmployees);
  };

  return (
    <Table
      stickyHeader
      sx={{
        marginBottom: '50px',
        marginTop: '5px',
        borderRadius: '4px 0px 0px 0px',
        background: 'var(--background-paper-elevation-1, #FFFFFF)',
        boxShadow: `0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F`,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell align='left' sx={tableHeaderStyles}>
            Employee
          </TableCell>
          {weekDays.map((weekDay, index) => (
            <TableCell
              key={index}
              align='center'
              sx={{
                ...tableHeaderStyles,
                backgroundColor: weekDay.isDisabled
                  ? 'rgba(255, 255, 255, 0.2)'
                  : 'inherit',
                opacity: weekDay.isDisabled ? 0.5 : 1,
              }}
            >
              <Typography>{weekDay.date}</Typography>
              <Typography sx={{ color: '#71717A' }}>{weekDay.day}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {employees.map((employee, employeeIndex) => (
          <TableRow key={employeeIndex} sx={tableRowStyles}>
            <TableCell
              align='left'
              sx={tableCellStyles}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Avatar
                alt={employee.name}
                src={employee.profileImage || ''}
                sx={{ marginRight: '10px' }}
              >
                {employee.name.charAt(0)}
              </Avatar>
              {employee.name}
            </TableCell>
            {weekDays.map((dayStatus, dayIndex) => {
              const attendanceStatus =
                employee.attendance[dayIndex]?.status || '';
              const abbreviation = getAbbreviation(attendanceStatus);
              const color = getRectangleColor(abbreviation);

              return (
                <TableCell
                  key={dayIndex}
                  align='center'
                  sx={{
                    ...tableCellStyles,
                    backgroundColor: dayStatus.isDisabled
                      ? 'rgba(255, 255, 255, 0.2)'
                      : 'inherit',
                    opacity: dayStatus.isDisabled ? 0.5 : 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {/* Dropdown for updating the status */}
                    <ChevronDropdown
                      abbreviation={abbreviation || ''}
                      options={dropdownOptions}
                      onUpdateAbbreviation={(newAbbreviation) =>
                        handleUpdateAbbreviation(
                          newAbbreviation,
                          employeeIndex,
                          dayIndex,
                        )
                      }
                      rectangleColor={color}
                    />
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

AttendanceTable.propTypes = {
  weekDays: PropTypes.array.isRequired,
};

export default AttendanceTable;

const tableHeaderStyles = {
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  textAlign: 'center',
  borderBottom: '1px solid var(--divider, #0000001F)',
  borderRight: '1px solid #E0E0E0',
  height: '40px',
};

const tableCellStyles = {
  borderBottom: '1px solid var(--divider, #0000001F)',
  borderRight: '1px solid #E0E0E0',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  textAlign: 'center',
  padding: '4px 8px',
  height: '48px',
};

const tableRowStyles = {
  backgroundColor: 'white',
  borderBottom: '1px solid var(--divider, #0000001F)',
};
