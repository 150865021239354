import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import { PayRollStatus } from '../../../enums/PayRollStatus';
import { format } from 'date-fns';
import {
  deletePayroll,
  getAllPayrolls,
  getAllPayRollsByYearAndMonth,
} from '../../../services/PayRollService';

import StatusChangeConfirmationModal from '../../alerts/StatusChangeConfirmationModal';
const { fonts, colors } = styleProps;

const headers = [
  'Pay runs',
  'Pay Date',
  'Employees',
  'Total Payment\n(LKR)',
  'Total Payment\n(USD)',
  'Status',
  'Action',
];

function formateDate(payrollDate) {
  const date = format(new Date(payrollDate), 'dd');
  const month = format(new Date(payrollDate), 'MMM');
  const year = format(new Date(payrollDate), 'yyyy');
  const fullDate = `${date} ${month} ${year}`;
  return fullDate;
}

// eslint-disable-next-line react/prop-types
const PayrollTable = ({ selectedYear, selectedMonth }) => {
  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  const [payrolls, setPayrolls] = useState([]);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState(false);
  const [selectedPayrollId, setSelectedPayrollId] = useState(null);
  const [enableModalTitle, setEnableModalTitle] = useState('');
  const [enableModalBody, setEnableModalBody] = useState('');

  useEffect(() => {
    fetchPayrolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonth]);

  const fetchPayrolls = async () => {
    try {
      let response;
      if (selectedYear && selectedMonth) {
        response = await getAllPayRollsByYearAndMonth(
          selectedYear,
          selectedMonth,
        );
      } else {
        response = await getAllPayrolls();
      }

      setPayrolls(response.message);
    } catch (error) {
      console.error('Error fetching payrolls:', error);
    }
  };

  const handleEditClick = async (displayId) => {
    try {
      window.location = `/payrollSchedule/${displayId}`;
    } catch (error) {
      console.error('Error fetching payroll data:', error);
    }
  };
  const handleDeleteClick = (displayId, status, payDate) => {
    const month = format(new Date(payDate), 'MMMM');
    setConfirmationModalStatus(true);
    setEnableModalTitle('Delete Payroll');
    setEnableModalBody(
      `Are you sure you want to Delete ${month} ${status} Payroll?`,
    );
    setSelectedPayrollId(displayId);
  };
  const confirmDeletePayroll = async () => {
    try {
      if (selectedPayrollId) {
        await deletePayroll(selectedPayrollId);
        fetchPayrolls();
      }
      setConfirmationModalStatus(false);
    } catch (error) {
      console.error('Error deleting payroll:', error);
    }
  };
  const closeModal = () => {
    setConfirmationModalStatus(false);
    setSelectedPayrollId(null);
  };
  function getActionButton(status, payrollId) {
    if (status === 'Scheduled') {
      return (
        <Button
          sx={{ ...buttonStyles, color: '#0666EB' }}
          onClick={() => handleEditClick(payrollId)}
        >
          Edit
        </Button>
      );
    } else if (status === 'Paid') {
      return <Button sx={{ ...buttonStyles, color: '#0666EB' }}>View</Button>;
    } else if (status === 'Draft') {
      return (
        <Button
          sx={{ ...buttonStyles, color: '#0666EB' }}
          onClick={() => handleEditClick(payrollId)}
        >
          Edit
        </Button>
      );
    } else if (status === 'Partialy Paid') {
      return (
        <Button
          sx={{ ...buttonStyles, color: '#0666EB' }}
          onClick={() => handleEditClick(payrollId)}
        >
          Edit
        </Button>
      );
    } else {
      return <Button>Unknown</Button>;
    }
  }
  // Mapping function to translate status
  function translateStatus(statusKey) {
    if (statusKey == 'D') {
      return PayRollStatus.D;
    } else if (statusKey == 'P') {
      return PayRollStatus.P;
    } else if (statusKey == 'PP') {
      return PayRollStatus.PP;
    } else if (statusKey == 'S') {
      return PayRollStatus.S;
    } else {
      return statusKey;
    }
  }
  return (
    <Table stickyHeader sx={{ marginBottom: '80px', marginTop: '40px' }}>
      <TableHead>
        <TableRow sx={{ border: 'none' }}>
          {headers.map((headerName, index) => {
            return (
              <TableCell
                key={index}
                align={headerName === 'Action' ? 'center' : 'left'}
                sx={tableHeaderStyles}
              >
                {headerName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody sx={{ border: 'none', backgroundColor: 'white' }}>
        <StatusChangeConfirmationModal
          modalStatus={confirmationModalStatus}
          title={enableModalTitle}
          text={enableModalBody}
          confirm={confirmDeletePayroll}
          reject={closeModal}
        ></StatusChangeConfirmationModal>
        {payrolls &&
          payrolls.map((payroll) => {
            return (
              <>
                <TableRow key={payroll.id} sx={{ backgroundColor: '#F5F7F8' }}>
                  <TableCell align='left' sx={tableCellStyles}>
                    {formateDate(payroll.startDate) +
                      ' - ' +
                      formateDate(payroll.endDate)}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {formateDate(payroll.payDate)}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {payroll.noEmployees}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(payroll.lkrAmount)}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(payroll.usdAmount)}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {translateStatus(payroll.status)}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: 'none',
                    }}
                  >
                    {getActionButton(
                      translateStatus(payroll.status),
                      payroll.displayId,
                    )}
                    <Button
                      sx={{ ...buttonStyles, color: '#EA5455' }}
                      onClick={() =>
                        handleDeleteClick(
                          payroll.displayId,
                          translateStatus(payroll.status),
                          payroll.payDate,
                        )
                      }
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: 'white',
                    height: '5px',
                  }}
                ></TableRow>
              </>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default PayrollTable;
const buttonStyles = {
  padding: '5px 10px',
  borderRadius: '5px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const tableHeaderStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: colors.textLightGrey,
};

const tableCellStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
};
