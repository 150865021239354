import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GhostButton from '../../../buttons/GhostButton';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SwipeableDrawer } from '@mui/material';
import ApproveSummary from '../../drawers/ApproveSummary';

const ApproveSummarySection = ({ report }) => {
  const [isOpenViewSummary, setIsOpenViewSummary] = useState(false);

  return (
    <>
      <GhostButton
        onClick={() => setIsOpenViewSummary(true)}
        icon={
          report.approvedByDm ? (
            <CheckCircleIcon
              sx={{ marginRight: '-6px', fontSize: '18px !important' }}
              htmlColor='#0666EB'
            />
          ) : (
            <PendingOutlinedIcon
              sx={{ marginRight: '-6px', fontSize: '18px !important' }}
              htmlColor='#0666EB'
            />
          )
        }
      >
        {report.approvedByDm ? 'View More' : 'Approve'}
      </GhostButton>
      <SwipeableDrawer
        anchor={'right'}
        open={isOpenViewSummary}
        onClose={() => setIsOpenViewSummary(false)}
      >
        <div style={{ width: 560 }}>
          <ApproveSummary
            report={report}
            onClose={() => setIsOpenViewSummary(false)}
          />
        </div>
      </SwipeableDrawer>
    </>
  );
};

ApproveSummarySection.propTypes = {
  report: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ApproveSummarySection;
