import { useState } from 'react';
import { addProjectSummary } from '../../../services/ProjectReportService';

const useSaveSummary = (reportId, summary) => {
  const [isLoading, setLoading] = useState(false);

  const handleSaveSummary = async () => {
    try {
      setLoading(true);
      const savedReport = await addProjectSummary(reportId, { summary });
      setLoading(false);
      return savedReport;
    } catch (error) {
      setLoading(false);
      console.error('Error while saving summary:', error);
    }
  };

  return { isLoading, handleSaveSummary };
};

export default useSaveSummary;
