/* eslint-disable react/prop-types */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { styleProps } from '../../../styles/styleProps';
import EditModal from '../modals/EditModal';
import { useState } from 'react';
import TooltipComponent from '../../tooltip/Tooltip';
const { fonts, colors } = styleProps;

const TableComponnet = ({ columns, data, bulkData, setBulkData }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState('');
  const [editModalData, setEditModalData] = useState('');
  const [editModalFieldId, setEditModalFieldId] = useState('');

  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const handleOpenEditModal = (title, data, fieldId) => {
    setEditModalTitle(title);
    setEditModalData(data);
    setEditModalFieldId(fieldId);
    setShowEditModal(true);
  };

  const hanelModalClose = () => {
    setEditModalTitle('');
    setEditModalData('');
    setEditModalFieldId('');
    setShowEditModal(false);
  };

  const handleChangeValue = ({ fieldId, value, id }) => {
    setBulkData((prev) => {
      const employees = prev.employees;
      const employee = employees[id];
      employee[fieldId] = value;
      employees[id] = employee;
      return { ...prev, employees };
    });
    bulkData.employees[id].calculateEarningsAndDeductions();
  };

  return (
    // add margin to show hidden content by fixed navbar
    <>
      <Table stickyHeader sx={{ marginBottom: '80px' }}>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            {columns.map((headerName, index) => {
              return (
                <TableCell key={index} align='left' sx={tableHeaderStyles}>
                  {headerName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: 'none', backgroundColor: 'white' }}>
          {data.map((data, index) => {
            return (
              <>
                <TableRow key={index} sx={{ backgroundColor: '#F5F7F8' }}>
                  <TableCell align='left' sx={tableCellStyles}>
                    <TooltipComponent
                      tooltipText={
                        <>
                          <TooltipText>{`Type: ${data.employeeType}`}</TooltipText>
                          <TooltipText>{`Currency: ${data.employeeCurrencyCode}`}</TooltipText>
                        </>
                      }
                      tooltipData={`${data.employeeFirstName ?? ''} ${
                        data.employeeLastName ?? ''
                      }`}
                    />
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeAllowanceFuel)}
                    <Typography
                      component='p'
                      sx={penIconStyles}
                      onClick={() =>
                        handleOpenEditModal(
                          'Fuel & Maintenance',
                          data,
                          'employeeAllowanceFuel',
                        )
                      }
                    >
                      <CreateIcon />
                    </Typography>
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeAllowanceTravel)}
                    <Typography
                      component='p'
                      sx={penIconStyles}
                      onClick={() =>
                        handleOpenEditModal(
                          'Traveling',
                          data,
                          'employeeAllowanceTravel',
                        )
                      }
                    >
                      <CreateIcon />
                    </Typography>
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeAllowanceDailyPay)}
                    <Typography
                      component='p'
                      sx={penIconStyles}
                      onClick={() =>
                        handleOpenEditModal(
                          'Daily Paid',
                          data,
                          'employeeAllowanceDailyPay',
                        )
                      }
                    >
                      <CreateIcon />
                    </Typography>
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeAllowanceOt)}
                    <Typography
                      component='p'
                      sx={penIconStyles}
                      onClick={() =>
                        handleOpenEditModal(
                          'Over time',
                          data,
                          'employeeAllowanceOt',
                        )
                      }
                    >
                      <CreateIcon />
                    </Typography>
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeAllowanceBonus)}
                    <Typography
                      component='p'
                      sx={penIconStyles}
                      onClick={() =>
                        handleOpenEditModal(
                          'Bonus',
                          data,
                          'employeeAllowanceBonus',
                        )
                      }
                    >
                      <CreateIcon />
                    </Typography>
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeReimbursements)}
                    <Typography
                      component='p'
                      sx={penIconStyles}
                      onClick={() =>
                        handleOpenEditModal(
                          'Reimbursement',
                          data,
                          'employeeReimbursements',
                        )
                      }
                    >
                      <CreateIcon />
                    </Typography>
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(data.employeeTotalEarnings)}
                  </TableCell>
                </TableRow>

                {/* This is the divider */}
                <TableRow
                  sx={{
                    backgroundColor: 'white',
                    height: '5px',
                  }}
                ></TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>

      {/* Edit Modal for editing table data */}
      <EditModal
        visible={showEditModal}
        handleClose={hanelModalClose}
        data={editModalData}
        fieldId={editModalFieldId}
        title={editModalTitle}
        onChange={(value) => {
          handleChangeValue({
            fieldId: editModalFieldId,
            id: editModalData.employeeDisplayId,
            value,
          });
        }}
      />
    </>
  );
};

export default TableComponnet;

const tableCellStyles = {
  position: 'relative',
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
  '&: hover': {
    '& p': {
      opacity: '1',
    },
  },
};

const tableHeaderStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: colors.textLightGrey,
  width: '10%',
};

const penIconStyles = {
  position: 'absolute',
  top: '50%',
  right: 10,
  transform: 'translate(-50%, -50%)',
  opacity: '0',
  cursor: 'pointer',
};

const TooltipText = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: 13,
});
