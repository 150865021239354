import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Input } from '@mui/material';
import styled from '@emotion/styled';
import { convertToMs, formatDuration } from '../../utils/TemporalService';

const CustomInput = styled(Input)({
  '&:before': {
    display: 'none',
  },
  '&:focus': {
    border: 'none',
  },
  '.MuiInputBase-input': {
    textAlign: 'center',
    padding: 'unset',
  },
  'input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  'input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
});

const TimeInputField = ({ timeInMs, onUpdate, onReset }) => {
  const { hrs, mins } = formatDuration(timeInMs);
  const initTime = { hours: hrs, minutes: mins };
  const [time, setTime] = useState(initTime);

  const didMountRef = useRef(false);

  useEffect(() => {
    // NOTE: didMountRef responsible to skip the execution of onUpdate(convertToMs(time)) during the first rendering of this component.
    // Otherwise, once the page is rendered for the first time, onUpdate function is executed once per report by default (unnecessary behavior) and the associate employee data will be marked as changed/updated even it is not.
    // To avoid this, if (didMountRef.current) is added.
    if (didMountRef.current) {
      if (JSON.stringify(initTime) === JSON.stringify(time)) {
        onReset(convertToMs(time));
      } else {
        onUpdate(convertToMs(time));
      }
    } else {
      didMountRef.current = true;
    }
  }, [time]);

  return (
    <Stack
      sx={{
        border: '1px solid blue',
        borderRadius: '8px',
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
      flexDirection={'row'}
      alignContent={'center'}
    >
      <Box alignContent={'center'} display={'inline-flex'} flexWrap={'wrap'}>
        <CustomInput
          sx={{ width: '36px' }}
          defaultValue={0}
          value={time.hours}
          onChange={(e) => setTime({ ...time, hours: Number(e.target.value) })}
          type='number'
        />
        <Typography
          sx={{ borderRadius: '8px', padding: '12px 0px' }}
          variant='h7'
        >
          hrs :
        </Typography>
        <CustomInput
          sx={{ width: '36px', padding: 'unset' }}
          defaultValue={0}
          value={time.minutes}
          onChange={(e) =>
            setTime({ ...time, minutes: Number(e.target.value) })
          }
          type='number'
        />
        <Typography
          sx={{ borderRadius: '8px', padding: '12px 0px' }}
          variant='h7'
        >
          mins
        </Typography>
      </Box>
    </Stack>
  );
};

TimeInputField.propTypes = {
  timeInMs: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default TimeInputField;
