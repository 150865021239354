import { useState } from 'react';
import { approveProjectSummary } from '../../../services/ProjectReportService';

const useApproveSummary = (reportId) => {
  const [isLoading, setLoading] = useState(false);

  const handleApproveSummary = async () => {
    try {
      setLoading(true);
      const approvedReport = await approveProjectSummary(reportId, {
        approvedBy: localStorage.getItem('adminId'),
      });
      setLoading(false);
      return approvedReport;
    } catch (error) {
      setLoading(false);
      console.error('Error while approving summary:', error);
    }
  };

  return { isLoading, handleApproveSummary };
};

export default useApproveSummary;
