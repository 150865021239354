/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Header from '../StepHeader';
import Table from '../tables/Step1-Table';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styleProps } from '../../../styles/styleProps';
import { getEmployeeByStatus } from '../../../services/EmployeeService';
import { EmployeeStatus } from '../../../enums/Employee';
import { useParams } from 'react-router-dom';
import { getPayRollById } from '../../../services/PayRollService';

function Step1(props) {
  const {
    selectedEmployees,
    setSelectedEmployees,
    setBulkData,
    bulkData,
    setErrorMessage,
  } = props;
  const urlParams = useParams();
  const payrollId = urlParams.payrollId;
  const { colors } = styleProps;
  const [employees, setEmployees] = useState([]);
  const [filteredData, setFilteredData] = useState(employees);
  const [searchValue, setSearchValue] = useState('');
  const { payrollStartDate, payrollEndDate, payDate } = bulkData;
  const toUTC = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  };

  useEffect(() => {
    // handles edit case if theres a payrollId in the url
    if (payrollId) {
      getPayRollById(payrollId).then((res) => {
        if (res.success) {
          const data = res.message.payRoll;
          const payStubs = res.message.payStubs;
          setBulkData((prev) => ({
            ...prev,
            payrollStartDate: new Date(data.startDate),
            payrollEndDate: new Date(data.endDate),
            payDate: new Date(data.payDate),
          }));
          setSelectedEmployees(
            new Set(payStubs.map((employee) => employee.employeeDisplayId)),
          );
        } else {
          // remove payroll id from url if it doesnt exist
          window.history.pushState({}, null, '/payrollSchedule');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setFilteredData(employees);
      return;
    }

    const filteredData = employees.filter((row) => {
      return (
        row.employeeEmployeeId.toLowerCase().includes(searchValue) ||
        row.employeeFirstName.toLowerCase().includes(searchValue) ||
        row.employeeLastName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filteredData);
  }, [searchValue, employees]);

  useEffect(() => {
    setErrorMessage('');

    if (payrollEndDate < payrollStartDate) {
      setErrorMessage('Payroll end date cannot be before payroll start date');
      setBulkData((prev) => ({ ...prev, payrollEndDate: null }));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollEndDate, payrollStartDate]);

  const columns = [
    'Employee ID',
    'Name',
    'Email',
    'Designation',
    'Salary',
    'Mobile',
  ];

  useEffect(() => {
    getAllEmployees();
  }, []);

  const getAllEmployees = async () => {
    const res = await getEmployeeByStatus(EmployeeStatus.ENABLED);
    setEmployees(res.employees);
  };

  return (
    <Box>
      <Header
        title='Pay Period & Employee'
        subtitle='Select the pay period and employee to include in this payroll'
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', marginY: 3 }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Payroll start date'
              slotProps={{ textField: { size: 'small' } }}
              value={payrollStartDate}
              onChange={(newValue) =>
                setBulkData((prev) => ({
                  ...prev,
                  payrollStartDate: toUTC(newValue),
                }))
              }
              format='dd/MMM/yyyy'
            />
            <DatePicker
              label='Payroll end date'
              slotProps={{ textField: { size: 'small' } }}
              minDate={payrollStartDate}
              value={payrollEndDate}
              onChange={(newValue) =>
                setBulkData((prev) => ({
                  ...prev,
                  payrollEndDate: toUTC(newValue),
                }))
              }
              format='dd/MMM/yyyy'
            />
            <DatePicker
              label='Pay date'
              slotProps={{ textField: { size: 'small' } }}
              minDate={payrollStartDate}
              value={payDate}
              disablePast
              onChange={(newValue) =>
                setBulkData((prev) => ({ ...prev, payDate: toUTC(newValue) }))
              }
              format='dd/MMM/yyyy'
            />
          </LocalizationProvider>
        </Box>

        {/* Search bar */}
        <Box>
          <TextField
            size='small'
            label='Search by Id or Name'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            InputProps={{
              endAdornment: <SearchIcon sx={{ color: colors.textLightGrey }} />,
            }}
          />
        </Box>
      </Box>
      <Table
        data={filteredData}
        columns={columns}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
      />
    </Box>
  );
}

export default Step1;
