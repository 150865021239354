import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from './BaseButton';

const GhostButton = ({ children, ...props }) => {
  return (
    <BaseButton
      variant='text'
      styles={{ height: 'unset', '&:hover': { background: 'unset' } }}
      {...props}
    >
      {children}
    </BaseButton>
  );
};

GhostButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default GhostButton;
