import { getCurrentTimeStamp } from '../utils/TemporalService';
import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

const yearDetails = getCurrentTimeStamp();

export const getEmployeeByDisplayId = async (employeeDisplayId) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.EMPLOYEES}?employeeId=${employeeDisplayId}`,
    true,
  );
  return data.message;
};

export const getEmployeeAccounts = async (userId) => {
  const year = yearDetails.year;
  const month = yearDetails.monthName;
  const employeeId = userId;

  const data = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.EMPLOYEE_ACCOUNT,
    { employeeId, month, year },
    true,
  );
  return data.message;
};

export const getEmployeeByStatus = async (status) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.EMPLOYEES}?status=${status}`,
  );
  return data.message;
};
