import { useState } from 'react';
import { editEmployeeTime } from '../../../services/ProjectReportService';

const useEditTime = (reportId) => {
  const [isLoading, setLoading] = useState(false);

  const handleEditDuration = async (updatedTimeRecords) => {
    try {
      setLoading(true);
      const modifiedReport = await editEmployeeTime(reportId, {
        updatedTimeRecords,
      });
      setLoading(false);
      return modifiedReport;
    } catch (error) {
      setLoading(false);
      console.error('Error while editing employee time duration:', error);
    }
  };

  return { isLoading, handleEditDuration };
};

export default useEditTime;
