import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import AddSummarySection from './summary-section/AddSummarySection';
import ApproveSummarySection from './summary-section/ApproveSummarySection';

const ReportSummary = ({ report, showEditSummary }) => {
  return report.summary ? (
    <Stack
      sx={{ padding: '2%' }}
      flexDirection={'row'}
      justifyContent={'center'}
    >
      <ApproveSummarySection report={report} />
    </Stack>
  ) : (
    <Stack
      sx={{ padding: '2%' }}
      flexDirection={'row'}
      justifyContent={'space-between'}
    >
      <Typography fontSize={20} variant='h7' fontWeight={400}>
        Summary
      </Typography>
      {showEditSummary ? <AddSummarySection report={report} /> : <></>}
    </Stack>
  );
};

ReportSummary.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
    summary: PropTypes.string,
  }).isRequired,
  showEditSummary: PropTypes.bool,
};

export default ReportSummary;
