const employeeData = [
  {
    name: 'Jason E. Gatlin',
    profileImage: '/static/images/avatar/2.jpg',
    attendance: [
      { day: 'Sun', status: 'Weekend' },
      { day: 'Mon', status: 'Present' },
      { day: 'Tue', status: null },
      { day: 'Wed', status: 'Present' },
      { day: 'Thu', status: 'Holiday' },
      { day: 'Fri', status: 'Present' },
      { day: 'Sat', status: 'Weekend' },
    ],
  },
  {
    name: 'Norma J. Haggerty',
    profileImage: '/static/images/avatar/2.jpg',
    attendance: [
      { day: 'Sun', status: 'Weekend' },
      { day: 'Mon', status: 'Present' },
      { day: 'Tue', status: 'Present' },
      { day: 'Wed', status: 'Medical/Casual Leave' },
      { day: 'Thu', status: 'Holiday' },
      { day: 'Fri', status: 'Present' },
      { day: 'Sat', status: 'Weekend' },
    ],
  },
  {
    name: 'Jessica M. Hill',
    profileImage: '/static/images/avatar/2.jpg',
    attendance: [
      { day: 'Sun', status: 'Weekend' },
      { day: 'Mon', status: null },
      { day: 'Tue', status: 'No Pay' },
      { day: 'Wed', status: 'Present' },
      { day: 'Thu', status: 'Holiday' },
      { day: 'Fri', status: 'Present' },
      { day: 'Sat', status: 'Weekend' },
    ],
  },
  {
    name: 'Javier S. Smith',
    profileImage: '/static/images/avatar/2.jpg',
    attendance: [
      { day: 'Sun', status: 'Weekend' },
      { day: 'Mon', status: 'Present' },
      { day: 'Tue', status: 'Present' },
      { day: 'Wed', status: 'Medical/Casual Leave' },
      { day: 'Thu', status: 'Holiday' },
      { day: 'Fri', status: '' },
      { day: 'Sat', status: 'Weekend' },
    ],
  },
];

export default employeeData;
