import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, TextField } from '@mui/material';
import ShortPrimaryBtn from '../../buttons/ShortPrimaryBtn';
import ShortSecondaryBtn from '../../buttons/ShortSecondaryBtn';
import DrawerLayout from './DrawerLayout';
import useSaveSummary from '../hooks/useSaveSummary';
import { useProjectReport } from '../../../contexts/project-reports-context/ProjectReportsContext';
import { CLOSING_DRAWER_TIMEOUT } from '../../../data/projectReportConstants';

const AddSummary = ({ report, onClose }) => {
  const [summary, setSummary] = useState(report?.summary ?? '');
  const { actions } = useProjectReport();
  const { setModifiedReport } = actions;

  const { isLoading, handleSaveSummary } = useSaveSummary(report.id, summary);

  const saveSummary = async () => {
    const savedReport = await handleSaveSummary();
    if (savedReport) {
      onClose();
      setTimeout(() => {
        setModifiedReport(savedReport);
      }, CLOSING_DRAWER_TIMEOUT);
    }
  };

  return (
    <DrawerLayout title={'Add project summary'} onClose={onClose}>
      <Stack
        sx={{ padding: '16px' }}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box sx={{ padding: '16px 0', marginBottom: '28px' }}>
          <TextField
            label='Add project summary'
            multiline
            rows={20}
            variant='outlined'
            fullWidth
            onChange={(e) => {
              setSummary(e.target.value);
            }}
            value={summary}
            margin='normal'
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px 0px',
            width: '100%',
          }}
        >
          <Stack
            sx={{ margin: '0 16px' }}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <ShortSecondaryBtn text={'Cancel'} onClick={onClose} />
            <ShortPrimaryBtn
              disabled={isLoading}
              text={isLoading ? 'Saving' : 'Save'}
              onClick={saveSummary}
            />
          </Stack>
        </Box>
      </Stack>
    </DrawerLayout>
  );
};

AddSummary.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
    summary: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddSummary;
