import React from 'react';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// eslint-disable-next-line react/prop-types
const DrawerLayout = ({ children, title, onClose }) => {
  return (
    <Box>
      <Stack
        sx={{ padding: '16px' }}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant='h7' fontSize={28} fontWeight={500}>
          {title}
        </Typography>
        <IconButton sx={{ padding: 'unset' }} onClick={onClose}>
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
      </Stack>
      <Divider />
      {children}
    </Box>
  );
};

export default DrawerLayout;
